import React, { useState } from 'react';

function App() {
  const [formData, setFormData] = useState({
    firstName: '',
	middleNames: [''], // Start with one middle name
	lastName: '',
    dateOfBirth: '',
    timeOfBirth: '',
    placeOfBirth: '',
    gender: '',
    email: ''
  });

const [errors, setErrors] = useState({
  firstName: '',
  middleNames: [],
  lastName: ''
});

// Helper function to check if name is valid (only letters, more than 2 chars)
const isValidName = (name) => /^[A-Za-z]+$/.test(name) && name.length > 2;

 // Handle changes in input fields
const handleChange = (e) => {
  const { name, value } = e.target;
  
  if (name === 'firstName' || name === 'lastName') {
    setFormData({
      ...formData,
      [name]: value
    });
	
	    // Validate immediately while typing
    if (!isValidName(value)) {
      setErrors({
        ...errors,
        [name]: 'Please expand the initials'
      });
    } else {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  } else if (name.startsWith('middleName')) {
    const index = parseInt(name.split('-')[1]);
    const newMiddleNames = [...formData.middleNames];
    newMiddleNames[index] = value;

    setFormData({
      ...formData,
      middleNames: newMiddleNames
    });
	// Validate middle names
    const newErrors = [...errors.middleNames];
    if (!isValidName(value)) {
      newErrors[index] = 'Please expand the initials';
    } else {
      newErrors[index] = '';
    }

    setErrors({
      ...errors,
      middleNames: newErrors
    });
  }
};

// Add new middle name input field
const addMiddleName = () => {
  setFormData({
    ...formData,
    middleNames: [...formData.middleNames, ''] // Add a new empty field
  });
};

// Remove middle name input field
const removeMiddleName = (index) => {
  const newMiddleNames = formData.middleNames.filter((_, i) => i !== index);
  
  setFormData({
    ...formData,
    middleNames: newMiddleNames
  });
};
// Validation check on form submission
const validateForm = () => {
  const firstNameValid = isValidName(formData.firstName);
  const lastNameValid = isValidName(formData.lastName);
  const middleNamesValid = formData.middleNames.every(isValidName);

  // Set validation errors for submission
  if (!firstNameValid || !lastNameValid || !middleNamesValid) {
    setErrors({
      firstName: firstNameValid ? '' : 'Please expand the initials',
      middleNames: formData.middleNames.map(mn => isValidName(mn) ? '' : 'Please expand the initials'),
      lastName: lastNameValid ? '' : 'Please expand the initials'
    });
    return false; // Prevent form submission
  }

  return true; // Allow form submission
};

// Form submission handler
const handleSubmit = (e) => {
  e.preventDefault();

  if (validateForm()) {
    // Proceed with form submission (e.g., send data to server)
    console.log('Form is valid and can be submitted:', formData);
  } else {
    console.log('Form validation failed.');
  }
};

  return (
  <div className="container">
	<div className="container-fluid p-5 bg-warning text-danger text-center">
		<h1 className="text-center">Divine Digits</h1>
		<h4 className="text-center">Numerolgy Report </h4>
		<p className="text-center"> Based on <strong>"GVS Method™ Numerology"</strong> <br/> <span className="small">Build 1.0.1 (Updated on 25-10-2024)</span></p>
	</div>
   
   <div className="container mt-5">
           <form onSubmit={handleSubmit}>
		   <div className="row">
		   <div className="col-md-8">
		   <p>Please enter your full name with expanded initials. If you have one or more middle names, please enter each name separately in the provided middle name boxes. Make sure each middle name is in a separate box for accurate results.</p>
						<p>For example:
						<ol> <li>Correct: John Michael Smith (if your name is J.M. Smith, expand it to John Michael Smith)</li>
						<li>Incorrect: J.M. Smith</li></ol></p>
						
				<div className="col-md-8">
					<div className="mb-3">
						
						<label htmlFor="firstName" className="form-label">First Name:</label>
						<input
						type="text"
						className="form-control"
						id="firstName"
						name="firstName"
						value={formData.firstName}
						onChange={handleChange}
						required
						/>
						 {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
					</div>
					{/* Middle Names */}
					{formData.middleNames.map((middleName, index) => (
					  <div className="mb-3" key={index}>
						<label htmlFor={`middleName-${index}`} className="form-label">Middle Name {index + 1}:</label>
						<input
						  type="text"
						  className="form-control"
						  id={`middleName-${index}`}
						  name={`middleName-${index}`}
						  value={middleName}
						  onChange={handleChange}
						/>
							  {errors.middleNames[index] && <p className="text-danger">{errors.middleNames[index]}</p>}
						<button type="button" onClick={() => removeMiddleName(index)}>Remove</button>
					  </div>
					))}

					<button type="button" onClick={addMiddleName}>Add Middle Name</button>

					<div className="mb-3">
					  <label htmlFor="lastName" className="form-label">Last Name:</label>
					  <input
						type="text"
						className="form-control"
						id="lastName"
						name="lastName"
						value={formData.lastName}
						onChange={handleChange}
						required
					  />
						  {errors.lastName && <p className="text-danger">{errors.lastName}</p>}
					</div>
			</div>
			</div>
			<div className="mb-3 col-md-4">
				<div className="">
				  <label htmlFor="dateOfBirth" className="form-label">Date of Birth:</label>
				  <input
					type="date"
					className="form-control"
					id="dateOfBirth"
					name="dateOfBirth"
					value={formData.dateOfBirth}
					onChange={handleChange}
					required
				  />
				</div>
				<div className="mt-3">
				  <label htmlFor="timeOfBirth" className="form-label">Time of Birth:</label>
				  <input
					type="time"
					className="form-control"
					id="timeOfBirth"
					name="timeOfBirth"
					value={formData.timeOfBirth}
					onChange={handleChange}
				  />
				</div>
				
				<div className="mt-3">
				<label htmlFor="placeOfBirth" className="form-label">Place of Birth (optional):</label>
				<input
				type="text"
				className="form-control"
				id="placeOfBirth"
				name="placeOfBirth"
				value={formData.placeOfBirth}
				onChange={handleChange}
				/>
				</div>
				<div className="mt-3">
				  <label htmlFor="gender" className="form-label">Gender:</label>
				  <select
					className="form-select"
					id="gender"
					name="gender"
					value={formData.gender}
					onChange={handleChange}
					required
				  >
					<option value="">Select</option>
					<option value="male">Male</option>
					<option value="female">Female</option>
					<option value="other">Other</option>
				  </select>
				</div>
				<div className="mt-3">
				  <label htmlFor="email" className="form-label">Email:</label>
				  <input
					type="email"
					className="form-control"
					id="email"
					name="email"
					value={formData.email}
					onChange={handleChange}
					required
				  />
				</div>
			</div>
		        

        
		</div>

        

        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </div>
</div>
  );
}

export default App;
